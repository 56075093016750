import React, { useState, useEffect } from 'react';
import { ArrowLeft, Check, Brain } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// HeatMap component stays the same
const KnowledgeHeatmap = ({ categories, scores }) => {
  const getColor = (score) => {
    const hue = (score * 1.2);
    return `hsl(${hue}, 70%, 50%)`;
  };

  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      {categories.map((category, index) => (
        <div
          key={category}
          className="p-4 rounded-lg text-white"
          style={{ backgroundColor: getColor(scores[index]) }}
        >
          <p className="font-semibold">{category}</p>
          <p className="text-xl">{scores[index]}%</p>
        </div>
      ))}
    </div>
  );
};

// Loading skeleton stays the same
const QuestionSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-3/4 mb-8"></div>
    <div className="space-y-4">
      {[...Array(4)].map((_, i) => (
        <div key={i} className="h-16 bg-gray-200 rounded"></div>
      ))}
    </div>
  </div>
);

// Main TrainingMode component
const TrainingMode = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [answerSelected, setAnswerSelected] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [categoryScores, setCategoryScores] = useState({
    ingredients: 0,
    recipes: 0,
    quantities: 0,
    techniques: 0,
  });

  // Handle navigation back to smoothie app
  const handleNavigateBack = () => {
    navigate('/smoothieapp');
  };

  // Categories and questions arrays stay the same
  const categories = [
    "Recipe Ingredients",
    "Quantity Measurements",
    "Special Instructions",
    "Recipe Identification"
  ];

  // Question bank stays the same
  const questions = [
    {
      question: "What are the ingredients for a small 'All Shook Up' smoothie?",
      options: [
        "3 sweetener, 3 banana, 1 black peanut butter, 1 blue + 1 green MRP, 2 tsp. cocoa",
        "5 sweetener, 4 banana, 1.5 black peanut butter, 2 green MRP, 3 tsp. cocoa",
        "3 sweetener, 2 banana, 1 black peanut butter, 2 blue MRP, 1 tsp. cocoa",
        "4 sweetener, 3 banana, 1 black peanut butter, 1 green MRP, 2 tsp. cocoa"
      ],
      correctAnswer: 0
    },
    {
      question: "Which smoothie contains acai as an ingredient?",
      options: ["All Shook Up", "Amazon Kick", "Big Bang", "Bubble Trouble"],
      correctAnswer: 1
    },
    {
      question: "How much cocoa is used in a large 'Chocolate Chimp' smoothie?",
      options: ["1 tsp.", "2 tsp.", "3 tsp.", "4 tsp."],
      correctAnswer: 2
    },
    {
      question: "What unique ingredient is added to the 'Chocolate Elvis' smoothie?",
      options: ["Energy blast", "Collagen peptides", "Granola", "Chia seeds"],
      correctAnswer: 0
    },
    {
      question: "How much Oreo cookie is used in a small 'Cookie Crusher' smoothie?",
      options: ["0.25 cup", "0.5 cup", "0.75 cup", "1 cup"],
      correctAnswer: 1
    },
    {
      question: "Which smoothie contains dragon fruit as an ingredient?",
      options: ["Amazon Kick", "Bubble Trouble", "Dragon Fruit Lemon Splash", "Fiji Island Breeze"],
      correctAnswer: 2
    },
    {
      question: "What type of milk is used in the 'Get Up & Glow' smoothie?",
      options: ["Almond milk", "Soy milk", "Oat milk", "Coconut milk"],
      correctAnswer: 2
    },
    {
      question: "How much coffee is added to a large 'Java The Nut' smoothie?",
      options: ["1 tsp.", "2 tsp.", "3 tsp.", "4 tsp."],
      correctAnswer: 2
    },
    {
      question: "Which smoothie includes leafy greens as an ingredient?",
      options: ["Lean Green Extreme", "Lunar Lemonade", "Mango Passion", "Mountain Man Crunch"],
      correctAnswer: 0
    },
    {
      question: "What is the main fruit ingredient in the 'Lunar Lemonade' smoothie?",
      options: ["Lemon", "Strawberry", "Banana", "Mango"],
      correctAnswer: 0
    },
    {
      question: "How many bananas are used in a large 'Chocolate Chimp' smoothie?",
      options: ["3", "4", "5", "6"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains Oreo cookies?",
      options: ["Cookie Crusher", "All Shook Up", "Big Bang", "Java The Nut"],
      correctAnswer: 0
    },
    {
      question: "What type of protein is used in the 'Big Bang' smoothie?",
      options: ["Soy protein", "Whey protein", "Pea protein", "Egg protein"],
      correctAnswer: 1
    },
    {
      question: "Which smoothie contains mango boba?",
      options: ["Bubble Trouble", "Mango Passion", "The Last Mango", "Fiji Island Breeze"],
      correctAnswer: 0
    },
    {
      question: "How much water is used in a small 'Captain Kid' smoothie?",
      options: ["No water", "1 water", "2 water", "3 water"],
      correctAnswer: 1
    },
    {
      question: "What gives the 'Get Up & Glow' smoothie its coffee flavor?",
      options: ["Coffee extract", "Espresso shot", "Coffee grounds", "2 tsp. coffee"],
      correctAnswer: 3
    },
    {
      question: "Which smoothie contains passion fruit?",
      options: ["Amazon Kick", "Fiji Island Breeze", "Dragon Fruit Lemon Splash", "Mango Passion"],
      correctAnswer: 1
    },
    {
      question: "What type of juice is used in the 'Lean Green Extreme' smoothie?",
      options: ["Apple juice", "Orange juice", "Pineapple juice", "Grape juice"],
      correctAnswer: 1
    },
    {
      question: "How many sweeteners are used in a small 'PB Blues' smoothie?",
      options: ["1", "2", "3", "4"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains chia seeds?",
      options: ["Mountain Man Crunch", "PB Dragon Fruit Riptide", "Get Up & Glow", "Peanut Butter Power Up"],
      correctAnswer: 1
    },
    {
      question: "What gives the 'Rio Berry Bliss' smoothie its blue color?",
      options: ["Blueberries", "Blue food coloring", "Acai", "Blue raspberry"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains an energy blast?",
      options: ["Road Runner", "Shag-A-Delic", "Java The Nut", "Mango Passion"],
      correctAnswer: 0
    },
    {
      question: "What type of sherbet is used in 'The Last Mango' smoothie?",
      options: ["Lemon sherbet", "Lime sherbet", "Mango sherbet", "Orange sherbet"],
      correctAnswer: 3
    },
    {
      question: "How much peanut butter is used in a large 'PBJ' smoothie?",
      options: ["1 black peanut butter", "1.5 black peanut butter", "2 black peanut butter", "2.5 black peanut butter"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains coconut water?",
      options: ["Strawberry Colada", "Fiji Island Breeze", "Amazon Kick", "Dragon Fruit Lemon Splash"],
      correctAnswer: 1
    },
    {
      question: "What gives the 'Strawberry Colada' its coconut flavor?",
      options: ["Coconut milk", "Coconut water", "Coconut extract", "Coconut flakes"],
      correctAnswer: 3
    },
    {
      question: "How many types of fruit are in the 'Vinnie Del Rocco' smoothie?",
      options: ["2", "3", "4", "5"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains granola?",
      options: ["Mountain Man Crunch", "Get Up & Glow", "Cookie Crusher", "PB Dragon Fruit Riptide"],
      correctAnswer: 1
    },
    {
      question: "What type of yogurt is used in most smoothies?",
      options: ["Greek yogurt", "Plain yogurt", "Vanilla yogurt", "Frozen yogurt"],
      correctAnswer: 3
    },
    {
      question: "Which smoothie contains the most varieties of berries?",
      options: ["Amazon Kick", "Rio Berry Bliss", "Twig & Berries", "Vinnie Del Rocco"],
      correctAnswer: 3
    },
    {
      question: "What gives the 'Java The Nut' its nutty flavor?",
      options: ["Almond butter", "Cashew butter", "Peanut butter", "Hazelnut spread"],
      correctAnswer: 2
    },
    {
      question: "How much honey is used in a small 'Mountain Man Crunch' smoothie?",
      options: ["1 honey", "1.5 honey", "2 honey", "2.5 honey"],
      correctAnswer: 1
    },
    {
      question: "Which smoothie contains plant-based protein?",
      options: ["Lean Green Extreme", "Big Bang", "Get Up & Glow", "PB Blues"],
      correctAnswer: 0
    },
    {
      question: "What type of milk is used in most smoothies that require milk?",
      options: ["Whole milk", "2% milk", "Skim milk", "Nonfat milk"],
      correctAnswer: 3
    },
    {
      question: "Which smoothie contains collagen peptides?",
      options: ["Get Up & Glow", "Java The Nut", "Chocolate Elvis", "PB Dragon Fruit Riptide"],
      correctAnswer: 0
    },
    {
      question: "How much vanilla is used in a large 'Mr. Mongo' smoothie?",
      options: ["2 vanilla", "4 vanilla", "6 vanilla", "8 vanilla"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains the most types of liquid ingredients?",
      options: ["Bubble Trouble", "Lean Green Extreme", "Road Runner", "Chocolate Elvis"],
      correctAnswer: 1
    },
    {
      question: "What gives the 'Chocolate Chimp' its chocolate flavor?",
      options: ["Chocolate syrup", "Cocoa powder", "Chocolate chips", "Chocolate milk"],
      correctAnswer: 1
    },
    {
      question: "Which smoothie contains oats?",
      options: ["Mountain Man Crunch", "Get Up & Glow", "Peanut Butter Power Up", "PB Dragon Fruit Riptide"],
      correctAnswer: 2
    },
    {
      question: "How much lemon juice is used in a large 'Dragon Fruit Lemon Splash' smoothie?",
      options: ["0.5 lemon juice", "1 lemon juice", "1.5 lemon juice", "2 lemon juice"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains the most bananas in its large size?",
      options: ["All Shook Up", "Chocolate Chimp", "PB Blues", "Peanut Butter Punch"],
      correctAnswer: 1
    },
    {
      question: "What type of strawberry is used in most smoothies?",
      options: ["Fresh strawberry", "Frozen strawberry", "Strawberry puree", "Strawberry syrup"],
      correctAnswer: 1
    },
    {
      question: "Which smoothie contains the most types of berries?",
      options: ["Amazon Kick", "Rio Berry Bliss", "Road Runner", "Vinnie Del Rocco"],
      correctAnswer: 2
    },
    {
      question: "How much pineapple is used in a small 'Fiji Island Breeze' smoothie?",
      options: ["0.5 blue pineapple", "1 blue pineapple", "1.5 blue pineapple", "2 blue pineapple"],
      correctAnswer: 1
    },
    {
      question: "Which smoothie contains the most sweetener in its large size?",
      options: ["All Shook Up", "Chocolate Elvis", "PB Blues", "Strawberry Colada"],
      correctAnswer: 0
    },
    {
      question: "What gives the 'Peanut Butter Power Up' its protein boost?",
      options: ["Whey protein", "Peanut butter", "Protein powder", "Frozen yogurt"],
      correctAnswer: 1
    },
    {
      question: "How much orange juice is used in a large 'Road Runner' smoothie?",
      options: ["1 orange juice", "1.5 orange juice", "2 orange juice", "2.5 orange juice"],
      correctAnswer: 2
    },
    {
      question: "Which smoothie contains peach as an ingredient?",
      options: ["Strawberry Colada", "The Last Mango", "Mango Passion", "Fiji Island Breeze"],
      correctAnswer: 1
    },
    {
      question: "What type of protein is used in the 'Lean Green Extreme' smoothie?",
      options: ["Whey protein", "Soy protein", "Pea protein", "Plant-based protein"],
      correctAnswer: 3
    },
    {
      question: "How many different MRP (Meal Replacement) colors are used in 'All Shook Up'?",
      options: ["1", "2", "3", "4"],
      correctAnswer: 1
    }
  ];

  // Initialize training session
  useEffect(() => {
    const initializeTraining = () => {
      setLoading(true);
      const shuffled = [...questions].sort(() => Math.random() - 0.5);
      const selected = shuffled.slice(0, 25);
      setSelectedQuestions(selected);
      setLoading(false);
    };

    initializeTraining();
  }, []);

  // Rest of the component logic stays the same
  const handleAnswerOptionClick = (index) => {
    if (answerSelected) return;

    setAnswerSelected(true);
    const correct = index === selectedQuestions[currentQuestion].correctAnswer;
    setIsCorrect(correct);
    setScore(correct ? score + 1 : score);
    
    const newProgress = ((currentQuestion + 1) / selectedQuestions.length) * 100;
    setProgress(newProgress);

    updateCategoryScores(
      selectedQuestions[currentQuestion].category,
      correct
    );

    setTimeout(() => {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion === selectedQuestions.length) {
        setShowScore(true);
      } else {
        setCurrentQuestion(nextQuestion);
        setAnswerSelected(false);
      }
    }, 1500);
  };

  // Rest of the helper functions stay the same
  const updateCategoryScores = (category, isCorrect) => {
    setCategoryScores(prev => {
      const currentTotal = prev[category] || 0;
      const questionsInCategory = selectedQuestions.filter(q => q.category === category).length;
      const newScore = isCorrect 
        ? ((currentTotal * questionsInCategory) + 100) / questionsInCategory
        : ((currentTotal * questionsInCategory)) / questionsInCategory;
      
      return {
        ...prev,
        [category]: Math.round(newScore)
      };
    });
  };

  const restartTraining = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setProgress(0);
    setAnswerSelected(false);
    setCategoryScores({
      ingredients: 0,
      recipes: 0,
      quantities: 0,
      techniques: 0,
    });
    
    const shuffled = [...questions].sort(() => Math.random() - 0.5);
    const selected = shuffled.slice(0, 25);
    setSelectedQuestions(selected);
  };

  if (loading || selectedQuestions.length === 0) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <header className="flex justify-between items-center mb-8">
          <button
            className="text-[#da018e] p-4 hover:bg-gray-200 rounded-full transition-colors"
            onClick={handleNavigateBack}
          >
            <ArrowLeft className="w-8 h-8" />
          </button>
          <h1 className="text-2xl font-bold text-[#da018e]">Training Mode</h1>
          <div className="w-8 h-8"></div>
        </header>
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
          <QuestionSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-black font-sans p-6">
      <header className="flex justify-between items-center mb-8">
        <button
          className="text-[#da018e] p-4 hover:bg-gray-200 rounded-full transition-colors"
          onClick={handleNavigateBack}
        >
          <ArrowLeft className="w-8 h-8" />
        </button>
        <h1 className="text-2xl font-bold text-[#da018e]">Training Mode</h1>
        <div className="w-8 h-8">
          <Brain className="text-[#da018e]" />
        </div>
      </header>

      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        {showScore ? (
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4 text-[#da018e]">Training Complete!</h2>
            <p className="text-xl mb-2">Your Score: {score} / {selectedQuestions.length}</p>
            <p className="text-xl mb-4">Progress: {progress}%</p>
            
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">Knowledge Areas</h3>
              <KnowledgeHeatmap 
                categories={categories}
                scores={[
                  categoryScores.ingredients,
                  categoryScores.quantities,
                  categoryScores.techniques,
                  categoryScores.recipes,
                ]}
              />
            </div>

            <button
              onClick={restartTraining}
              className="w-full bg-[#da018e] text-white py-2 rounded-full font-semibold hover:bg-[#da018e]/90 transition-colors mb-4"
            >
              Retry
            </button>
            <button
              onClick={handleNavigateBack}
              className="w-full bg-gray-200 text-[#da018e] py-2 rounded-full font-semibold hover:bg-gray-300 transition-colors"
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <div className="mb-6">
              <h2 className="text-xl font-bold mb-4">
                {selectedQuestions[currentQuestion].question}
              </h2>
              <div className="bg-gray-200 rounded-full h-4 mb-4">
                <div
                  className="bg-[#da018e] h-full rounded-full transition-all duration-500 ease-out"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-600">
                Question {currentQuestion + 1} of {selectedQuestions.length}
              </p>
            </div>
            <div className="space-y-4">
              {selectedQuestions[currentQuestion].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerOptionClick(index)}
                  className={`w-full p-4 rounded-lg text-left transition-colors ${
                    answerSelected
                      ? index === selectedQuestions[currentQuestion].correctAnswer
                        ? 'bg-green-500 text-white'
                        : 'bg-red-500 text-white'
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                  disabled={answerSelected}
                >
                  {option}
                  {answerSelected && index === selectedQuestions[currentQuestion].correctAnswer && (
                    <Check className="inline-block ml-2 w-5 h-5" />
                  )}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TrainingMode;