import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Import pages and components
import Landing from './pages/Landing';
import TrainingMode from './components/TrainingMode';
import SmoothieApp from './components/SmoothieApp';
import Subscription from './pages/Subscription';
import User from './components/UserLicensePanel';

import SignUpForm from './components/SignUpForm';

// Layout component for consistent styling
const Layout = ({ children }) => (
  <div className="min-h-screen bg-gray-100 text-black font-sans">
    {children}
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {/* Redirect root to the landing page */}
          <Route path="/" element={<Navigate to="/smoothieapp" replace />} />

          {/* Public routes */}
          <Route path="/landing" element={<Landing />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/training" element={<TrainingMode />} />   
          <Route path="/smoothieapp" element={<SmoothieApp />} />
          <Route path="/user" element={<User />} />

          {/* Catch-all route for 404 Not Found */}
          <Route path="*" element={<Navigate to="/landing" replace />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;