import React from 'react';
import { Check, X } from 'lucide-react';

const PricingModal = ({ onClose }) => {
  // Subscription plans data
  const plans = [
    {
      title: 'Basic',
      price: 9.99,
      features: ['1 smoothie per day', 'Basic flavors', 'In-store pickup'],
    },
    {
      title: 'Pro',
      price: 19.99,
      features: ['2 smoothies per day', 'All flavors', 'In-store pickup', 'Mobile ordering'],
      isPopular: true,
    },
  ];

  // Handler for plan selection
  const handlePlanSelect = () => {
    // Redirect to Stripe checkout
    window.location.href = 'https://buy.stripe.com/bIY1774qw4512SkbIL';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <h2 className="text-4xl font-bold mb-6 text-center">Choose Your Smoothie Plan</h2>
        <p className="text-xl text-gray-600 text-center mb-8">
          Enjoy delicious, healthy smoothies with our flexible subscription options.
        </p>
        <div className="grid md:grid-cols-2 gap-8 mb-8">
          {plans.map((plan, index) => (
            <div key={index} className={`bg-white rounded-lg p-6 shadow-md ${plan.isPopular ? 'border-2 border-[#da018e]' : ''}`}>
              {plan.isPopular && (
                <div className="bg-[#da018e] text-white text-xs font-bold px-3 py-1 rounded-full inline-block mb-4">
                  MOST POPULAR
                </div>
              )}
              <h3 className="text-xl font-bold mb-2">{plan.title}</h3>
              <div className="text-3xl font-bold mb-4">${plan.price}<span className="text-xl font-normal">/mo</span></div>
              <ul className="mb-6">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center mb-2">
                    <Check className="w-5 h-5 mr-2 text-[#da018e]" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <button
                onClick={handlePlanSelect}
                className="w-full bg-[#da018e] text-white py-2 rounded-full font-semibold hover:bg-[#da018e]/90 transition-colors"
              >
                Get started now
              </button>
            </div>
          ))}
        </div>
        <div className="text-center text-sm text-gray-600">
          © 2024 Planet Smoothie. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default PricingModal;