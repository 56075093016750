import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ROICalculator = () => {
  const [monthlySales, setMonthlySales] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [roi, setRoi] = useState(null);

  const calculateROI = () => {
    const sales = parseFloat(monthlySales);
    const employees = parseInt(employeeCount);
    if (isNaN(sales) || isNaN(employees)) {
      setRoi(null);
      return;
    }
    const increasedSales = sales * 0.05;
    const employeeSavings = employees * 500;
    const annualIncrease = (increasedSales * 12) + employeeSavings;
    setRoi(annualIncrease);
  };

  return (
    <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
      <h3 className="text-2xl font-bold mb-4 text-[#e40079]">ROI Calculator</h3>
      <div className="space-y-4">
        <div>
          <label htmlFor="monthlySales" className="block text-sm font-medium text-[#333333] mb-1">
            Monthly Sales ($)
          </label>
          <input
            id="monthlySales"
            type="number"
            value={monthlySales}
            onChange={(e) => setMonthlySales(e.target.value)}
            placeholder="Enter your monthly sales"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e40079]"
          />
        </div>
        <div>
          <label htmlFor="employeeCount" className="block text-sm font-medium text-[#333333] mb-1">
            Number of Employees
          </label>
          <input
            id="employeeCount"
            type="number"
            value={employeeCount}
            onChange={(e) => setEmployeeCount(e.target.value)}
            placeholder="Enter number of employees"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e40079]"
          />
        </div>
        <button 
          onClick={calculateROI} 
          className="w-full bg-[#e40079] text-white py-2 rounded-md hover:bg-[#c8006c] transition-colors"
        >
          Calculate ROI
        </button>
        {roi !== null && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-4 bg-[#f7f7f7] rounded-lg"
          >
            <p className="text-sm text-[#333333]">Estimated Annual Benefit:</p>
            <p className="text-2xl font-semibold text-[#e40079]">${roi.toFixed(2)}</p>
            <p className="text-xs text-[#666666] mt-2">
              This estimate is based on a projected 5% increase in sales efficiency
              and $500 annual savings per employee. Actual results may vary.
            </p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ROICalculator;