// UserLicensePanel.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckCircle, XCircle, AlertTriangle, RefreshCw, CreditCard, Edit2, ArrowLeft, Clock, Shield } from 'lucide-react';

// Initialize Supabase client
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Card component for consistent styling
const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg p-6 shadow-md ${className}`}>
    {children}
  </div>
);

// Card header component
const CardHeader = ({ children }) => (
  <div className="mb-6">{children}</div>
);

// Card title component
const CardTitle = ({ children }) => (
  <h3 className="text-xl font-bold mb-2">{children}</h3>
);

// Card content component
const CardContent = ({ children }) => (
  <div>{children}</div>
);

// Progress bar component
const Progress = ({ value }) => (
  <div className="w-full bg-gray-200 rounded-full h-2">
    <div 
      className="bg-[#da018e] h-2 rounded-full transition-all duration-300" 
      style={{ width: `${value}%` }}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
);

// Alert component for displaying warnings and info
const Alert = ({ children, variant = 'warning' }) => {
  const bgColor = variant === 'warning' ? 'bg-yellow-50' : 'bg-blue-50';
  const borderColor = variant === 'warning' ? 'border-yellow-200' : 'border-blue-200';
  
  return (
    <div className={`${bgColor} ${borderColor} border rounded-lg p-4`} role="alert">
      {children}
    </div>
  );
};

// Button component for consistent styling
const Button = ({ children, variant = 'primary', disabled = false, onClick, className = '' }) => {
  const baseStyles = "py-2 px-4 rounded-full font-semibold transition-colors";
  const variants = {
    primary: "bg-[#da018e] text-white hover:bg-[#da018e]/90",
    outline: "border-2 border-[#da018e] text-[#da018e] hover:bg-[#da018e] hover:text-white"
  };

  return (
    <button 
      className={`${baseStyles} ${variants[variant]} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

// UpdatePaymentMethod component for Stripe integration
const UpdatePaymentMethod = ({ onClose, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      setError("Stripe has not loaded. Please try again later.");
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }

    try {
      // Call Supabase Edge Function to update payment method
      const { data, error: supabaseError } = await supabase.functions.invoke('update-payment-method', {
        body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
      });

      if (supabaseError) throw supabaseError;

      onSuccess(data);
    } catch (err) {
      setError('Failed to update payment method. Please try again.');
      console.error('Error updating payment method:', err);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Update Payment Method</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={!stripe || processing}>
              {processing ? 'Updating...' : 'Update Payment Method'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Main UserLicensePanel component
const UserLicensePanel = () => {
  const navigate = useNavigate();
  
  // State management
  const [licenseData, setLicenseData] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [error, setError] = useState(null);
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState(null);

  // Function to sync license data to local storage
  const syncLicenseToLocalStorage = useCallback((data) => {
    localStorage.setItem('licenseInfo', JSON.stringify({
      expiry_date: data.expiry_date,
      last_synced: new Date().toISOString(),
      status: data.status,
      subscription_plan: data.subscription_plan
    }));
  }, []);

  // Fetch license data from Supabase
  const fetchLicenseData = useCallback(async () => {
    try {
      setError(null);
      // Fetch user license data
      const { data: licenseData, error: licenseError } = await supabase
        .from('user_licenses')
        .select('*')
        .single();

      if (licenseError) throw licenseError;

      // Fetch payment methods for this license
      const { data: paymentData, error: paymentError } = await supabase
        .from('payment_methods')
        .select('*')
        .eq('user_license_id', licenseData.id);

      if (paymentError) throw paymentError;

      setLicenseData(licenseData);
      setPaymentMethods(paymentData);

      // Update local storage
      syncLicenseToLocalStorage(licenseData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch license data. Please try again later.');
    }
  }, [syncLicenseToLocalStorage]);

  // Effect to fetch initial data and set up event listeners
  useEffect(() => {
    fetchLicenseData();

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [fetchLicenseData]);

  // Function to check and notify about license expiry
  const checkAndNotify = useCallback(() => {
    const licenseInfo = JSON.parse(localStorage.getItem('licenseInfo'));
    if (!licenseInfo) return;

    const daysUntilExpiry = Math.ceil((new Date(licenseInfo.expiry_date) - new Date()) / (1000 * 60 * 60 * 24));

    if (daysUntilExpiry <= 7 && Notification.permission === 'granted') {
      new Notification('License Expiring Soon', {
        body: `Your license will expire in ${daysUntilExpiry} days. Please renew to maintain access.`,
      });
    }
  }, []);

  // Effect to check for notifications
  useEffect(() => {
    checkAndNotify();
  }, [checkAndNotify]);

  // Utility function to calculate days until expiry
  const daysUntilExpiry = () => {
    if (!licenseData) return 0;
    const expiry = new Date(licenseData.expiry_date);
    const today = new Date();
    const diffTime = expiry - today;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  // Utility function to get status color
  const getStatusColor = () => {
    if (!licenseData) return 'text-gray-500';
    if (licenseData.status === 'active') return 'text-[#da018e]';
    if (licenseData.status === 'expired') return 'text-red-500';
    return 'text-yellow-500';
  };

  // Sync handler
  const handleSync = async () => {
    setIsSyncing(true);
    setError(null);
    try {
      await fetchLicenseData();
      // Update last_synced timestamp
      await supabase
        .from('user_licenses')
        .update({ last_synced: new Date().toISOString() })
        .eq('id', licenseData.id);
    } catch (error) {
      console.error('Error syncing data:', error);
      setError('Failed to sync license data. Please try again later.');
    } finally {
      setIsSyncing(false);
    }
  };

  // Handle back navigation
  const handleBack = () => {
    navigate(-1);
  };

  // Handle updating payment method
  const handleUpdatePaymentMethod = (method) => {
    setUpdatingPaymentMethod(method);
    setShowUpdatePaymentModal(true);
  };

  // Handle successful payment method update
  const handlePaymentMethodUpdateSuccess = async (updatedMethod) => {
    setShowUpdatePaymentModal(false);
    await fetchLicenseData(); // Refresh the data to show the updated payment method
  };

  // Usage metric component
  const UsageMetric = ({ current, limit, label }) => (
    <div className="space-y-2">
      <div className="flex justify-between text-sm">
        <span className="text-gray-600">{label}</span>
        <span className="font-medium">{current} / {limit}</span>
      </div>
      <Progress value={(current / limit) * 100} />
    </div>
  );

  // Payment method card component
  const PaymentMethodCard = ({ method, isDefault }) => (
    <div className="flex items-center justify-between p-4 border rounded-lg mb-2 hover:bg-gray-50 transition-colors">
      <div className="flex items-center space-x-4">
        <CreditCard className={`w-6 h-6 ${isDefault ? 'text-[#da018e]' : 'text-gray-400'}`} />
        <div>
          <p className="font-medium">
            {method.brand} •••• {method.last4}
            {isDefault && (
              <span className="ml-2 text-xs bg-pink-100 text-[#da018e] px-2 py-1 rounded">
                Default
              </span>
            )}
          </p>
          <p className="text-sm text-gray-500">Expires {method.expiry_month}/{method.expiry_year}</p>
        </div>
      </div>
      <div className="flex gap-2">
        {!isDefault && (
          <Button variant="outline" className="text-sm">
            Set Default
          </Button>
        )}
        <Button variant="outline" className="text-sm" onClick={() => handleUpdatePaymentMethod(method)}>
          <Edit2 className="w-4 h-4" />
          <span className="sr-only">Edit payment method</span>
        </Button>
      </div>
    </div>
  );

  // If license data is not loaded yet, show a loading state
  if (!licenseData) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-black font-sans p-6">
      {/* Header */}
      <header className="flex justify-between items-center mb-8">
        <button
          onClick={handleBack}
          className="text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors"
          aria-label="Go back"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <h1 className="text-2xl font-bold">License Management</h1>
        <div className="w-6" /> {/* Spacer */}
      </header>

      <div className="max-w-4xl mx-auto space-y-6">
        {/* Quick Actions */}
        <div className="flex justify-end">
          <Button 
            variant="outline" 
            onClick={handleSync}
            disabled={isSyncing || !isOnline}
            className="flex items-center gap-2"
          >
            <RefreshCw className={`w-4 h-4 ${isSyncing ? 'animate-spin' : ''}`} />
            {isSyncing ? 'Syncing...' : 'Sync License'}
          </Button>
        </div>

        {/* Main Content Grid */}
        <div className="grid gap-6 md:grid-cols-2">
          {/* License Status Card */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                License Status
                <span className={`flex items-center gap-2 ${getStatusColor()}`}>
                  {licenseData.status === 'active' ? (
                    
                    <CheckCircle className="w-5 h-5" />
                  ) : (
                    <XCircle  className="w-5 h-5" />
                  )}
                  {licenseData.status.charAt(0).toUpperCase() + licenseData.status.slice(1)}
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">License ID</h3>
                  <p className="mt-1 font-mono">{licenseData.license_id}</p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Plan</h3>
                  <p className="mt-1">{licenseData.subscription_plan}</p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Expires</h3>
                  <p className="mt-1 flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    {new Date(licenseData.expiry_date).toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Last Sync</h3>
                  <p className="mt-1 flex items-center gap-2">
                    <Shield className={`w-4 h-4 ${isOnline ? 'text-[#da018e]' : 'text-gray-400'}`} />
                    {new Date(licenseData.last_synced).toLocaleDateString()}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-sm font-medium text-gray-500">Usage Statistics</h3>
                <UsageMetric 
                  current={licenseData.active_users} 
                  limit={licenseData.user_limit} 
                  label="Active Users"
                />
              </div>
            </CardContent>
          </Card>

          {/* Billing Card */}
          <Card>
            <CardHeader>
              <CardTitle>Billing Overview</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">{licenseData.subscription_plan}</span>
                  <span className="text-lg font-semibold">
                    ${licenseData.billing_amount}
                    <span className="text-base font-normal">/{licenseData.billing_interval}</span>
                  </span>
                </div>
                <p className="text-sm text-gray-500">
                  Next billing: {new Date(licenseData.next_billing_date).toLocaleDateString()}
                </p>
              </div>

              <div className="space-y-2">
                {paymentMethods.map((method) => (
                  <PaymentMethodCard 
                    key={method.id}
                    method={method}
                    isDefault={method.is_default}
                  />
                ))}
                <Button variant="outline" className="w-full">
                  Add Payment Method
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Alerts */}
        <div className="space-y-4">
          {daysUntilExpiry() <= 30 && (
            <Alert variant="warning">
              <div className="flex gap-2">
                <AlertTriangle className="h-5 w-5 text-yellow-600" />
                <div>
                  <h4 className="font-semibold text-yellow-600">Subscription Expiring Soon</h4>
                  <p className="text-sm text-yellow-700">
                    Your subscription will expire in {daysUntilExpiry()} days. Please renew to maintain access.
                  </p>
                </div>
              </div>
            </Alert>
          )}

          {!isOnline && (
            <Alert variant="info">
              <div className="flex gap-2">
                <AlertTriangle className="h-5 w-5 text-blue-600" />
                <div>
                  <h4 className="font-semibold text-blue-600">Offline Mode</h4>
                  <p className="text-sm text-blue-700">
                    You're currently working offline. Some features may be limited until you reconnect.
                  </p>
                </div>
              </div>
            </Alert>
          )}

          {error && (
            <Alert variant="warning">
              <div className="flex gap-2">
                <AlertTriangle className="h-5 w-5 text-red-600" />
                <div>
                  <h4 className="font-semibold text-red-600">Error</h4>
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </Alert>
          )}
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-12 text-center text-sm text-gray-600">
        © 2024 All rights reserved.
      </footer>

      {/* Update Payment Method Modal */}
      {showUpdatePaymentModal && (
        <Elements stripe={stripePromise}>
          <UpdatePaymentMethod
            onClose={() => setShowUpdatePaymentModal(false)}
            onSuccess={handlePaymentMethodUpdateSuccess}
          />
        </Elements>
      )}
    </div>
  );
};

export default UserLicensePanel;