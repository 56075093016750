import React from 'react';
import { ArrowLeft, Check, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

// SubscriptionCard component to display subscription options
const SubscriptionCard = ({ title, price, features, isPopular, onSelect }) => (
  <div className={`bg-white rounded-lg p-6 shadow-md ${isPopular ? 'border-2 border-[#da018e]' : ''}`}>
    {isPopular && (
      <div className="bg-[#da018e] text-white text-xs font-bold px-3 py-1 rounded-full inline-block mb-4">
        MOST POPULAR
      </div>
    )}
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <div className="text-3xl font-bold mb-4">${price}<span className="text-xl font-normal">/mo</span></div>
    <ul className="mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-2">
          <Check className="w-5 h-5 mr-2 text-[#da018e]" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <button
      onClick={onSelect}
      className="w-full bg-[#da018e] text-white py-2 rounded-full font-semibold hover:bg-[#da018e]/90 transition-colors"
    >
      Get started now
    </button>
  </div>
);

// Main SubscriptionPage component
const SubscriptionPage = () => {
  // Subscription plans data
  const plans = [
    {
      title: 'Basic',
      price: 9.99,
      features: ['1 smoothie per day', 'Basic flavors', 'In-store pickup'],
    },
    {
      title: 'Pro',
      price: 19.99,
      features: ['2 smoothies per day', 'All flavors', 'In-store pickup', 'Mobile ordering'],
      isPopular: true,
    },
  ];

  // Handler for plan selection
  const handlePlanSelect = () => {
    // Redirect to Stripe checkout
    window.location.href = 'https://buy.stripe.com/bIY1774qw4512SkbIL';
  };

  return (
    <div className="min-h-screen bg-gray-100 text-black font-sans p-6">
      {/* Header */}
      <header className="flex justify-between items-center mb-8">
        <Link to="/" className="text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors">
          <ArrowLeft className="w-6 h-6" />
        </Link>
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg" 
          alt="Planet Smoothie Logo" 
          className="h-12"
        />
        <div className="w-6 h-6"></div> {/* Placeholder for symmetry */}
      </header>

      {/* Main content */}
      <main className="max-w-4xl mx-auto">
        {/* Hero section */}
        <section className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Choose Your Smoothie Plan</h1>
          <p className="text-xl text-gray-600">
            Enjoy delicious, healthy smoothies with our flexible subscription options.
          </p>
        </section>

        {/* Subscription plans */}
        <section className="grid md:grid-cols-2 gap-8 mb-12">
          {plans.map((plan, index) => (
            <SubscriptionCard
              key={index}
              {...plan}
              onSelect={handlePlanSelect}
            />
          ))}
        </section>

        {/* Custom quote section */}
        <section className="bg-white rounded-lg p-6 shadow-md">
          <div className="flex items-center mb-4">
            <Phone className="w-6 h-6 mr-2 text-[#da018e]" />
            <h3 className="text-xl font-bold">Let's chat</h3>
          </div>
          <p className="text-gray-600 mb-6">
            Looking for a custom plan? Let's hop on a quick call to create the perfect smoothie subscription for you.
          </p>
          <button className="w-full bg-white text-[#da018e] py-2 rounded-full font-semibold border-2 border-[#da018e] hover:bg-[#da018e] hover:text-white transition-colors">
            Book a call
          </button>
        </section>
      </main>

      {/* Footer */}
      <footer className="mt-12 text-center text-sm text-gray-600">
        © 2024 Planet Smoothie. All rights reserved.
      </footer>
    </div>
  );
};

export default SubscriptionPage;