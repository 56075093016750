import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiSmartphone, FiBarChart2, FiClock, FiUsers, FiChevronRight, FiStar, FiZap, FiTrendingUp, FiDownload } from 'react-icons/fi';

// Import components
import PlanetSmoothie from '../components/SmoothieApp';
import ROICalculator from '../components/ROICalculator';
import PricingModal from '../components/PricingModal';

export default function Component() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800 font-sans">
      {/* Header */}
      <header className={`px-4 lg:px-6 h-20 flex items-center sticky top-0 bg-white z-50 transition-all duration-300 ${isScrolled ? 'shadow-md' : 'border-b border-gray-200'}`}>
        <a href="/" className="flex items-center justify-center">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg"
            alt="Planet Smoothie Logo"
            className="h-12 w-auto"
          />
        </a>
        <nav className="ml-auto hidden md:flex gap-6">
          <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#features">Features</a>
          <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#benefits">Benefits</a>
          <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#roi">ROI</a>
          <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#contact">Contact</a>
          <button 
            className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors"
            onClick={() => setShowPricingModal(true)}
          >
            Pricing
          </button>
        </nav>
        <button 
          className="ml-auto md:hidden text-gray-600 hover:text-pink-600"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? 'Close' : 'Menu'}
        </button>
      </header>

      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-r from-pink-400 to-pink-600">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="space-y-2"
              >
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-white">
                  BOOST YOUR SMOOTHIE FRANCHISE PROFITS
                </h1>
                <p className="mx-auto max-w-[700px] text-white md:text-xl">
                  Increase efficiency by 20%, reduce training time by 50%, and improve customer satisfaction with our innovative Smoothie Guide app.
                </p>
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="space-x-4"
              >
                <a href="#download" className="inline-block bg-white text-pink-600 hover:bg-gray-100 rounded-full px-6 py-3 text-lg font-semibold transition-colors">
                  GET STARTED
                  <FiChevronRight className="inline-block ml-2" />
                </a>
                <a href="#demo" className="inline-block text-white border-2 border-white hover:bg-white hover:text-pink-600 rounded-full px-6 py-3 text-lg font-semibold transition-colors">
                  TRY DEMO
                </a>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-pink-600">
              KEY FEATURES
            </h2>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
              {[
                { icon: FiSmartphone, title: "Mobile-Friendly", description: "Access recipes and guides on any device, anytime, anywhere." },
                { icon: FiBarChart2, title: "30+ Recipes", description: "Comprehensive guide with over 30 smoothie recipes and variations." },
                { icon: FiClock, title: "Quick Reference", description: "Easily search and find recipes for faster customer service." },
                { icon: FiUsers, title: "Staff Training", description: "Perfect tool for training new employees and ensuring consistency." }
              ].map((feature, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6"
                >
                  <feature.icon className="h-10 w-10 mb-2 text-pink-600" />
                  <h3 className="text-xl font-semibold mb-2 text-pink-600">{feature.title}</h3>
                  <p className="text-gray-700">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Interactive Demo Section */}
        <section id="demo" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-pink-600">
              TRY OUR INTERACTIVE SMOOTHIE GUIDE
            </h2>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <PlanetSmoothie />
            </motion.div>
          </div>
        </section>

        {/* Benefits Section */}
        <section id="benefits" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-pink-600">
              BENEFITS FOR FRANCHISE OWNERS
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-4">
              {[
                { icon: FiZap, title: "20% Increased Efficiency", description: "Reduce order preparation time and improve accuracy with easy-to-follow recipes." },
                { icon: FiUsers, title: "50% Faster Training", description: "Cut new employee training time in half with our intuitive guide and recipe system." },
                { icon: FiStar, title: "15% Higher Customer Satisfaction", description: "Improve taste consistency and reduce wait times, leading to happier customers." },
                { icon: FiTrendingUp, title: "10% Revenue Growth", description: "Streamlined operations and improved customer experience lead to increased sales." }
              ].map((benefit, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6 text-center"
                >
                  <benefit.icon className="h-12 w-12 mb-4 mx-auto text-pink-600" />
                  <h3 className="text-xl font-semibold mb-2 text-pink-600">{benefit.title}</h3>
                  <p className="text-gray-700">{benefit.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* ROI Calculator Section */}
        <section id="roi" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-pink-600">
              CALCULATE YOUR ROI
            </h2>
            <div className="flex justify-center">
              <ROICalculator />
            </div>
          </div>
        </section>

        {/* Download Section (Updated for PWA) */}
        <section id="download" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-pink-600">
              GET STARTED TODAY
            </h2>
            <p className="text-center text-gray-700 mb-8 max-w-2xl mx-auto">
              Experience the power of our Smoothie Guide app as a Progressive Web App (PWA). Install it directly on your device for quick and easy access, no app store required!
            </p>
            <div className="flex justify-center">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-pink-600 text-white hover:bg-pink-700 flex items-center px-6 py-3 rounded-md transition-colors text-lg font-semibold"
                onClick={handleInstallClick}
                disabled={!deferredPrompt}
              >
                <FiDownload className="mr-2" />
                Install Smoothie Guide PWA
              </motion.button>
            </div>
            <p className="text-center text-sm text-gray-600 mt-4">
              Compatible with all modern browsers and devices. No app store download required.
            </p>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-pink-600">
                  READY TO TRANSFORM YOUR FRANCHISE?
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-700 md:text-xl">
                  Get in touch with us to learn how our Smoothie Guide can benefit your business.
                </p>
              </div>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="w-full max-w-sm bg-white rounded-lg shadow-md p-6"
              >
                <form className="flex flex-col space-y-4">
                  <input 
                    placeholder="Your Name" 
                    type="text" 
                    className="w-full p-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-pink-600" 
                  />
                  <input 
                    placeholder="Your Email" 
                    type="email" 
                    className="w-full p-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-pink-600" 
                  />
                  <input 
                    placeholder="Your Phone" 
                    type="tel" 
                    className="w-full p-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-pink-600" 
                  />
                  <button 
                    type="submit" 
                    className="bg-pink-600 text-white hover:bg-pink-700 rounded-full py-2 px-4 text-lg font-semibold transition-colors"
                  >
                    REQUEST A DEMO
                    <FiChevronRight className="inline-block ml-2" />
                  </button>
                </form>
              </motion.div>
            
            </div>
          </div>
        </section>
      </main>

      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-gray-200">
        <p className="text-xs text-gray-700">
          © 2024 Smoothie Guide App. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs hover:underline underline-offset-4 text-gray-700 hover:text-pink-600" href="#">
            Terms of Service
          </a>
          <a className="text-xs hover:underline underline-offset-4 text-gray-700 hover:text-pink-600" href="#">
            Privacy Policy
          </a>
        </nav>
      </footer>

      {showPricingModal && <PricingModal onClose={() => setShowPricingModal(false)} />}
      {isMobileMenuOpen && (
        <div className="absolute top-20 left-0 right-0 bg-white shadow-md p-4 md:hidden">
          <nav className="flex flex-col gap-4">
            <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#features" onClick={toggleMobileMenu}>Features</a>
            <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#benefits" onClick={toggleMobileMenu}>Benefits</a>
            <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#roi" onClick={toggleMobileMenu}>ROI</a>
            <a className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors" href="#contact" onClick={toggleMobileMenu}>Contact</a>
            <button 
              className="text-sm font-semibold uppercase hover:text-pink-600 transition-colors text-left"
              onClick={() => {
                setShowPricingModal(true);
                toggleMobileMenu();
              }}
            >
              Pricing
            </button>
          </nav>
        </div>
      )}
    </div>
  );
}